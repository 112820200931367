import React from "react";
import { BsTrophyFill } from "react-icons/bs";
import { SiAntdesign } from "react-icons/si";
import { BiCodeAlt } from "react-icons/bi";
import { IoLogoYoutube } from "react-icons/io";
import FunFactCard from "./FunFactCard";

const FunFact = () => {
  return (
    <div className="nes-container is-dark with-title ">
      <p className="title">Random Things </p>
  
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 pb-10">
      <FunFactCard icon={<BsTrophyFill />} des="Created a TedX Intro Video" />
      <FunFactCard icon={<SiAntdesign />} des="Built an 'AI' robot, which won prizes." />
      <FunFactCard icon={<BiCodeAlt />} des="Built a self balancing robot with PID controller." />
      <FunFactCard icon={<IoLogoYoutube />} des="I plan on replacing these" />
      </div>
    </div>
  );
};

export default FunFact;
